import React, { useEffect, useState } from 'react';
import './BreederCard.scss';
import { Avatar, Button, Card, Col, Row, Typography, message } from 'antd';
import FormatMoney from '../../utils/FormatMoney';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import RegisterWallPopup from '../../popups/RegisterWall/RegisterWallPopup';
import { getToken } from '../../utils/authHelpers';
import { useGetUser } from '../../utils/useGetUser';
import { collection, deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import ContactBreederPopup from '../../popups/ContactBreeder/ContactBreederPopup';
import { useGetBreeder } from '../../utils/useGetBreeder';
import { UserOutlined } from '@ant-design/icons';

interface BreederCardProps {
	id: number | string;
	name: string;
	previewUrl: string;
	ownerId?: any;
	ownerName: string;
	ownerProfileImageUrl: string;
	pricing: number;
	favorite?: boolean;
	favoriteId?: number;
	category?: string;
	onForceCb?: () => void;
	showPrice?: boolean;
	dadImage?: string;
	mumImage?: string;
	pedigreePaperDam?: string;
	pedigreePaperSire?: string;
	description?: string;
	dogType?: string;    // for pup profile and upcoming litters
	type?: string;       // for stud profile
	pupName?: string;    // for showcase
}

const BreederCard: React.FC<BreederCardProps> = ({
	id,
	name,
	previewUrl,
	ownerId,
	ownerName,
	ownerProfileImageUrl,
	pricing,
	favorite,
	favoriteId,
	category,
	onForceCb,
	showPrice,
	dadImage,
	mumImage,
	pedigreePaperDam,
	pedigreePaperSire,
	description,
	dogType,
	type,
	pupName
}) => {
	const { user }: any = useGetUser();
	const [saved, setSaved] = useState<boolean>(favorite || false);
	const [auth] = useState<boolean>(getToken() !== '' ? true : false);
	const [openRegisterWall, setOpenRegisterWall] = useState<boolean>(false);
	const [openContactBreeder, setOpenContactBreeder] = useState<boolean>(false);
	const { breeder: breederInfo }: any = useGetBreeder(ownerId);

	const breeder: any = {
		uid: ownerId
	};

	const navigate = useNavigate();
	const [randomVector, setRandomVector] = useState('');
	let defaultPreviewImage = randomVector ? require(`../../assets/images/vectors/${randomVector}.png`) : '';

	// Function to get the correct display name based on category
	const getDisplayName = () => {
		switch(category) {
			case 'studProfile':
				return type || name;
			case 'showcase':
				return pupName || name;
			case 'pupProfile':
			case 'upcomingLitters':
				return dogType || name;
			default:
				return name;
		}
	};

	const onContactBreeder = () => {
		setOpenContactBreeder(true);
	};

	useEffect(() => {
		const fileNames = ['corgie', 'dash', 'golden', 'hairy', 'poodle'];
		const randomIndex = Math.floor(Math.random() * fileNames.length);
		if (!randomVector) setRandomVector(fileNames[randomIndex]);
	}, [randomVector]);

	useEffect(() => {
		const loadInitValues = () => {
			if (!user || favorite) return;
			if (!Array.isArray(user.favorites) || !user.favorites.length) return;
			setSaved(user.favorites.some((e: any) => e.animal.id === id));
			let filteredFavorite = user.favorites.filter((e: any) => e.animal.id === id);
			if (!Array.isArray(filteredFavorite) || !filteredFavorite.length) return;
		};
		loadInitValues();
	}, [user, id, favorite]);

	const viewBreeder = () => {
		if (!ownerId) return;
		if (auth) {
			navigate(`/breeder/${ownerId}`);
		} else {
			setOpenRegisterWall(true);
		}
	};

	const viewAnimal = () => {
		if (!id) return;
		if (auth) {
			navigate(`/animal/${id}/?category=${category}`);
		} else {
			setOpenRegisterWall(true);
		}
	};

	const onSave = async () => {
		if (!id || typeof id !== 'string') return;
		if (!user) return;
		try {
			const collectionRef = collection(db, 'Users', user.uid, 'Favorites');
			const favRef = doc(collectionRef, id);
			await setDoc(favRef, {
				id,
				date: serverTimestamp(),
				user: user.uid,
				category
			});
			message.success('Successfully added to favorites.');
			setSaved(!saved);
		} catch (error) {
			console.log(error);
			message.error(`Failed to add to saved list.`);
		}
	};

	const onUnsave = async () => {
		if (!id || typeof id !== 'string') return;
		if (!user) return;

		try {
			const collectionRef = collection(db, 'Users', user.uid, 'Favorites');
			const favRef = doc(collectionRef, id);
			await deleteDoc(favRef);
			message.success('Successfully removed to favorites.');
			setSaved(!saved);
			if (onForceCb) {
				onForceCb();
			}
		} catch (error) {
			message.error(`Failed to remove to saved list.`);
		}
	};

	return (
		<Card
			className="breederCard"
			bordered={false}
			cover={
				category === 'upcomingLitters' ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							width: '100%',
							alignItems: 'center',
							height: '100%'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								width: '100%',
								alignItems: 'center',
								height: '100%'
							}}
						>
							<div style={{ position: 'relative', maxWidth: '100%' }}>
								<div
									style={{
										position: 'absolute',
										zIndex: 99,
										padding: '3px',
										backgroundColor: '#9595958e',
										borderRadius: '5px',
										width: '4rem',
										textAlign: 'center',
										left: '1rem',
										color: 'white'
									}}
								>
									dad
								</div>
								<Image
									onClick={viewAnimal}
									preview={false}
									className="breederCardImg"
									width="10rem"
									alt="breeder preview"
									src={dadImage ? dadImage : defaultPreviewImage}
								/>
								<a
									href={pedigreePaperSire}
									style={{
										border: '1px solid #9595958e',
										color: '#9595958e',
										padding: '4px',
										display: 'flex',
										textAlign: 'center',
										borderRadius: '10px',
										marginTop: '1rem'
									}}
									target="_blank"
									rel="noreferrer"
								>
									Sire Pedigree Paper View
								</a>
							</div>
							<div style={{ position: 'relative', maxWidth: '100%' }}>
								<div
									style={{
										position: 'absolute',
										zIndex: 99,
										padding: '3px',
										backgroundColor: '#9595958e',
										borderRadius: '5px',
										width: '4rem',
										textAlign: 'center',
										left: '1rem',
										color: 'white'
									}}
								>
									mum
								</div>
								<Image
									onClick={viewAnimal}
									preview={false}
									className="breederCardImg"
									width="10rem"
									alt="breeder preview"
									src={mumImage ? mumImage : defaultPreviewImage}
								/>
								<a
									href={pedigreePaperDam}
									style={{
										border: '1px solid #9595958e',
										color: '#9595958e',
										padding: '4px',
										display: 'flex',
										textAlign: 'center',
										borderRadius: '10px',
										marginTop: '1rem'
									}}
									target="_blank"
									rel="noreferrer"
								>
									Dam Pedigree Paper View
								</a>
							</div>
						</div>
					</div>
				) : (
					<div style={{ overflowY: 'hidden', maxHeight: '400px' }}>
						<Image
							onClick={viewAnimal}
							preview={false}
							className="breederCardImg"
							alt="breeder preview"
							src={previewUrl ? previewUrl : defaultPreviewImage}
						/>
					</div>
				)
			}
			hoverable
		>
			<div className="breederCardContent">
				<Row gutter={[16, 16]} align={'middle'} justify={'space-between'}>
					<Col span={18}>
						<Typography.Title onClick={viewAnimal} className="breederCardName" level={4}>
							{getDisplayName()}
						</Typography.Title>
					</Col>
					{user && (
						<Col className="breederCardLikeCta" span={user.isBuyer ? 6 : 0}>
							<i
								onClick={saved ? onUnsave : onSave}
								className={`ri-heart-${saved ? `fill saved` : `line`} ri-lg`}
							></i>
						</Col>
					)}
					{
						<Col span={24}>
							<Row align={'middle'} justify={'space-between'}>
								<Col onClick={viewBreeder} md={16} lg={16} xl={16} xxl={16}>
									<div className="ownerInfoRow">
										<Avatar
											shape="circle"
											src={
												ownerProfileImageUrl
												? ownerProfileImageUrl
												: breederInfo
												? breederInfo?.businessPic
												: null
											}
											icon={!ownerProfileImageUrl && !breederInfo?.businessPic && <UserOutlined />}
										/>
										<Typography.Title type="secondary" className="ownerName" level={5}>
											{breederInfo?.businessName}
										</Typography.Title>
									</div>
								</Col>
								<Col onClick={viewBreeder} md={8} lg={8} xl={8} xxl={8} className="pricingContent">
									<Typography.Title level={5} className="pricingText">
										{showPrice ? FormatMoney(pricing) : null}
									</Typography.Title>
								</Col>
							</Row>
						</Col>
					}
				</Row>
				{(category === 'upcomingLitters' || category === 'studProfile') && (
					<div style={{ width: '100%', marginTop: '1rem' }}>
						<Button onClick={onContactBreeder} style={{ width: '100%' }} size="large" type="primary">
							Contact breeder
						</Button>
					</div>
				)}

				<RegisterWallPopup
					opened={openRegisterWall}
					onCancel={() => setOpenRegisterWall(false)}
					onForceCb={() => console.log('object')}
				/>
			</div>
			<ContactBreederPopup
				opened={openContactBreeder}
				breeder={breeder}
				onCancel={() => setOpenContactBreeder(false)}
				onForceCb={() => console.log('object')}
			/>
		</Card>
	);
};

export default BreederCard;